import React from "react"

export default function KeyInfoItem(props) {
    let value = props.value

    if (typeof value === 'string' || value instanceof String) {
        if (value.includes('null')) {
            return null
        }
        value = value.replace(';', ', ')
    }

    if (value) {
        return (
            <div className="key-info-item">
                <h3>{props.label}</h3>
                <h4>{value}</h4>
            </div>
        )
    }
    else {
        return null
    }
}
