import React from 'react'
import KeyInfoItem from './KeyInfoItem'
import { getAgeFromDateOfBirth } from '../../scripts/helpers'

export default function KeyInfo(props) {
    const contact = props.contact

    let keyFields = [
      { label: 'Gender', value: contact.genders },
      { label: 'Height', value: contact.measurements.height + ' cm' },
      { label: 'Ethnicity', value: contact.info.ethnicity },
      { label: 'Nationality', value: contact.info.nationality },
      { label: 'Region', value: contact.info.closestCentre },
      { label: 'Hair Colour', value: contact.info.hairColour },
      { label: 'Top Size', value: contact.measurements.shirtTopSize },
      { label: 'Jean Size', value: contact.measurements.jeanSize },
      { label: 'Shoe Size', value: contact.measurements.shoeSize + ' (US)' },
      { label: 'Chest', value: contact.measurements.chestBust + ' cm' },
      { label: 'Waist', value: contact.measurements.waist + ' cm' },
      { label: 'Hip', value: contact.measurements.hip + ' cm' },
      { label: 'Eyes', value: contact.info.eyeColour },
    ]

    const age = getAgeFromDateOfBirth(contact.dateOfBirth)
    const maxAgeToDisplay = 18
    if (age <= maxAgeToDisplay) {
      keyFields.push(
        { label: 'Age', value: age },
      )
    }

    //Filter out empty values
    keyFields = keyFields.filter(
      field => field.value && field.value !== '0' && field.value !== '0 cm'
    )

    return (
        <div className="key-information">

          <div className="key-info-container">
            { 
              keyFields.map(( field ) => (
                  <KeyInfoItem key={ 'key-info-item-' + field.label } label={ field.label } value={ field.value } />
              ))
            }  
          </div>

        </div>
    )
}
