import React from 'react';

const Video = ({videoUrl}) => {
    
    if (!videoDomainIsSupported(videoUrl)) {
        return null;
    }

    let sourceUrl;

    //Youtube
    if (videoUrl.includes('youtube')) {
        sourceUrl = videoUrl.replace('watch?v=', 'embed/');
    }
    //Dropbox
    else if (videoUrl.includes('dropbox')) {
        sourceUrl = videoUrl.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
        sourceUrl = sourceUrl.replace('?dl=0', '');
    }
    //Vimeo
    else if (videoUrl.includes('vimeo')) {
        sourceUrl = videoUrl.replace('vimeo.com', 'player.vimeo.com/video');
    }
    //Bunny Stream
    //The URL is from the "Copy Video Play URL" menu option in Bunny Stream
    else if (videoUrl.includes('mediadelivery.net')) { 
        sourceUrl = videoUrl.replace('/play/', '/embed/');
        if (!sourceUrl.includes('?') && !sourceUrl.includes('autoplay')) {
            sourceUrl = sourceUrl + '?autoplay=false';
        }
    }

    return (
        <div className='video'>
            <iframe width='560' height='315' 
                src={sourceUrl}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen />
        </div>
    )
}

const videoDomainIsSupported = (videoUrl) => {
    const supportedDomains = [
        'youtube.com',
        'dropbox.com',
        'vimeo.com',
        'mediadelivery.net' //Bunny Stream
    ]

    const videoUrlDomainIsSupported = supportedDomains.some(v => videoUrl.includes(v))
    return videoUrlDomainIsSupported;
}

export default Video;