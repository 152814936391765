
import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getFullNameFromFirstAndLastNames, objectHasValues, convertImageNameToAltText, 
  removeTileImages, isValidUrl } from "../scripts/helpers"
import { SRLWrapper } from "simple-react-lightbox"
import Slider from "react-slick"
import { Helmet } from 'react-helmet'

import store from "store"
import ShortlistButton from "../components/Shortlist/ShortlistButton"
import KeyInfo from "../components/Profile/KeyInfo"
import AttributeSection from "../components/Profile/AttributeSection"
import { galleryOptions, carouselSettings, twoImageCarouselSettings, oneImageCarouselSettings } from "../scripts/settings"
import VideoEmbeds from "../components/Profile/VideoEmbeds"


//** DUPLICATED FROM PROFILE TILE
// For some reason this doesn't give correct results when I use the external version of the function
const contactIsInShortlist = (contactToCheckId) => {
  //Check for empty shortlist
  if (!store.get('shortlist')) {
    return false
  }

  let currentShortlist = store.get('shortlist')
  let inShortlist = false

  currentShortlist.forEach((shortlistContact) => {
    if (contactToCheckId === shortlistContact.id) {
      inShortlist = true
    }
  });

  return inShortlist
}

const ensureInstagramIsCompleteUrl = (contactInstagram) => {
  if (!contactInstagram) {
    return contactInstagram
  }
  
  const instagramBaseUrl = 'https://instagram.com/'
  
  //If the field was entered with an @ at the start with their username instead of a url
  if (contactInstagram.startsWith('@')) {
    const username = contactInstagram.substring(1)
    return instagramBaseUrl + username
  }
  else {
    return contactInstagram
  }
}


export default function Profile({ data }) {
  const profileData = data.allContacts.edges[0].node

  const images = removeTileImages(data.allS3Object.nodes)

  //Clone object instead of just reference so I can freely delete elements used elsewhere
  let contactExperienceExcludingTraining = {...profileData.experience}

  if (contactExperienceExcludingTraining) {
    delete contactExperienceExcludingTraining.training
  }

  //Manage carousel when there is only one image ** Ideally it wouldn't be a carousel at all
  let imageCarouselSettings = carouselSettings
  if (images.length === 2) {
    imageCarouselSettings = twoImageCarouselSettings
  }
  else if (images.length === 1) {
    imageCarouselSettings = oneImageCarouselSettings
  }

  const fullname = profileData.fullname ? profileData.fullname : getFullNameFromFirstAndLastNames(profileData.firstname, profileData.lastname)
  const instagramUrl = ensureInstagramIsCompleteUrl(profileData.info.instagramUrl)
  const resumeUrl = profileData.info.resumeDownloadUrl || '';

  let inShortlist = contactIsInShortlist(profileData.id)

  const categories = profileData.categories ? profileData.categories.split(';') : []

  let currentPageUrl = ''
  if (typeof window !== `undefined`){ 
    currentPageUrl = window.location.pathname
    if (currentPageUrl.endsWith('/')) {
      currentPageUrl = currentPageUrl.slice(0, -1)
    }
  }

  return (
    <Layout>

      <Helmet>
        <title>{fullname} Profile</title>
        <meta name="description" content={`Profile page for ${fullname}`} />
      </Helmet>

      <title>{fullname}</title>

      <div className="page-content profile-page">

        <div className="profile-heading">
          <h1>{fullname}</h1>
          <ShortlistButton contact={profileData} inShortlist={inShortlist} iconSize={40} />

          { profileData.info.closestCentre &&
                    <h2>{profileData.info.closestCentre.split(';').join(', ')}</h2> }

          <ul className="profile-header-categories">
            {categories.map((category) => {
                  return (
                    <li key={category}>{category}</li>
                  )
                })}
          </ul>

        </div>
        

        <div className="profile-explore">

          <ul className="explore-links">
            { currentPageUrl && 
              <li>
                <Link to={currentPageUrl + '-headcard'}>
                  Headcard
                </Link>
              </li>
            }

            { instagramUrl && 
              <li>
                <Link to={instagramUrl} target="_blank" rel="noreferrer" >
                  Instagram
                </Link>
              </li>
            }

            { resumeUrl && isValidUrl(resumeUrl) &&
              <li>
                <Link to={profileData.info.resumeDownloadUrl} target="_blank" rel="noreferrer" >
                  Resume
                </Link>
              </li>
            }
          </ul>
            
        </div>


        <div className="profile-image-gallery">

          <div className="profile-image-container">
              <SRLWrapper options={galleryOptions}>
                <Slider {...imageCarouselSettings}> 
                  {images.map((image) => {
                    return (
                      <GatsbyImage key={image.Key} image={getImage(image.localFile)} objectFit="contain" 
                        alt={convertImageNameToAltText(image.Key, 'profile image')} />
                    )
                  })}
                </Slider>
              </SRLWrapper>
          </div>

        </div>


        <KeyInfo contact={profileData} />


        <div className="profile-detailed-info">

          {objectHasValues(profileData.info.introduction) &&
            <AttributeSection attributes={profileData.info.introduction} section="introduction" />
          }

          {objectHasValues(profileData.experience.training) &&
            <AttributeSection attributes={profileData.experience.training} section="training" />
          }

          {objectHasValues(contactExperienceExcludingTraining) &&
            <AttributeSection attributes={contactExperienceExcludingTraining} section="experience" />
          }

          {objectHasValues(profileData.skills) &&
            <AttributeSection attributes={profileData.skills} section="skills" />
          }

        </div>

        <VideoEmbeds contact={profileData} />

      </div>
    </Layout>
  )
}


export const query = graphql`
    query (
      $id: String!
      $imageRegex: String!
      ) 
      {
        allContacts(filter: {id: {eq: $id}}) {
          edges {
            node {
              id
              firstname
              lastname
              fullname
              dateOfBirth
              genders
              sortNumber
              status
              categories
              modelSubcategories
              talentSubcategories
              experience {
                TVCommercialExp
                TVDramaExp
                featureFilmExp
                modellingExp
                shortFilmExp
                theatreExp
                training
                voiceMcPresentingExp
                webSeriesExp
                backgroundExtrasExp
                bodyDoubleStandInExp
              }
              skills {
                accentSkills
                artisticSkills
                athleteSkills
                circusSkills
                combatAndStuntsSkills
                danceSkills
                drivingSkills
                flyingSkills
                horseSkills
                languages
                miscSkillsAttributes
                musicAndSingingSkills
                otherSkills
                otherSportsSkills
                voiceoverMcPresentingSkills
                waterSportsSkills
                winterSportsSkills
              }
              measurements {
                braCupSize
                chestBust
                height
                hip
                jeanSize
                kidsClothingSize
                otherMeasurements
                shirtTopSize
                shoeSize
                waist
                seat
                head
              }
              info {
                city
                ethnicity
                closestCentre
                eyeColour
                hairColour
                nationality
                nativeLanguage
                naturalAccent
                instagramUrl
                introduction
                videoShowreelUrl
                videoOtherUrl
                resumeDownloadUrl
              }
              fields {
                slug
              }
            }
          }
        }
        allS3Object(
          filter: {
            Key: {regex: $imageRegex},
            Size: {ne: 0}
          }
          limit: 14
          ) {
          nodes {
            Key
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 880)
              }
            }
          }
        }
      }
  `