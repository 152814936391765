import React from "react"

export default function SkillItem(props) {
    const section = props.section
    const name = props.name
    let value = props.value

    const presentableNames = {
        //Skills
        accentSkills: "Accents",
        artisticSkills: "Artistic",
        athleteSkills: "Athlete",
        circusSkills: "Circus",
        combatAndStuntsSkills: "Combat, Weapons, Stunts, Martial Arts",
        danceSkills: "Dance",
        drivingSkills: "Driving",
        flyingSkills: "Flying",
        horseSkills: "Equestrian",
        languages: "Languages",
        musicAndSingingSkills: "Music & Singing",
        voiceoverMcPresentingSkills: "Voiceover, MC, Presenting",
        waterSportsSkills: "Water Sports",
        winterSportsSkills: "Winter Sports",
        otherSkills: "Other Skills",
        otherSportsSkills: "Other Sports",
        miscSkillsAttributes: "Miscellaneous",

        //Experience
        featureFilmExp: "Feature Film",
        modellingExp: "Modelling",
        shortFilmExp: "Short Film",
        theatreExp: "Theatre",
        TVCommercialExp: "TV Commercial",
        TVDramaExp: "TV Drama",
        voiceMcPresentingExp: "Voice, MC, Presenting",
        webSeriesExp: "Web Series",
        backgroundExtrasExp: "Background Extras", //** Test this */
        bodyDoubleStandInExp: "Body Double / Stand In", // ** Test this
    }

    //Format checklist selection for reading. By default it looks like e.g. Chef;Waiter;Bartender
    if (name === "miscSkillsAttributes" && value) {
        value = value.replace(/;/g, ", ");
    }

    if (value) {
        return (
            <div className={"attribute-item " + section}>
                <h3 className="attribute-item-heading">{presentableNames[name]}</h3>
                <p>{value}</p>
            </div>
        )
    }
    else {
        return null
    }
    
}
