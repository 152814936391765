import React from 'react';
import Video from './Video';
import { isValidUrl } from '../../scripts/helpers';

export default function VideoEmbeds(props) {
    const contact = props.contact;
    let videoUrls = [];

    const videoUrlFieldNames = ['videoShowreelUrl', 'videoOtherUrl'];

    videoUrlFieldNames.forEach(fieldName => {
        if (contact.info && contact.info[fieldName]) {

            const url = contact.info[fieldName];

            if (isValidUrl(url)) {
                videoUrls.push(url);
            }
            else {
                console.error(`Invalid URL: ${url}`);
            }
        }
    });

    if (videoUrls.length === 0) {
        return null;
    }

    let videoSectionTitle = 'Video';
    if (videoUrls.length > 1) {
        videoSectionTitle = 'Videos';
    }

    return (
        <div className='videos-embedded'>
          <h2 className='video-embeds-heading'>{videoSectionTitle}</h2>

            <div className='videos-wrapper'>
                {videoUrls.map((url, index) => {
                    return <Video key={index} videoUrl={url} />
                })}
            </div>
        </div>
    )

}