import React from "react"
import AttributeItem from "./AttributeItem"
import { objectHasValues } from "../../scripts/helpers"

export default function AttributeSection(props) {
    const attributes = props.attributes
    let attributesKeyArray = Object.keys(attributes)
    
    // Remove training because it's a special case treated above, and non-specific values of Yes or No
    attributesKeyArray = attributesKeyArray.filter(item => item !== 'training' && item !== 'No' && item !== 'Yes') 

    const section = props.section

    if (typeof attributes === 'string' || attributes instanceof String) { // If only one value passed in
      return (
        <div className={"attributes-container " + section}>
          <h2 className="attributes-heading">{section}</h2>

            <div className="attributes-section">
                <AttributeItem section={section} name={section} value={ attributes } />
            </div>

        </div>
      )
    }
    else {
      //Remove 'Yes' and 'No' values that shouldn't be displayed
      const valuesToHide = ['No', 'Yes']
      for (const [key, value] of Object.entries(attributes)) {
        if (valuesToHide.includes(value)) {
          delete attributes[key] //delete from attribute object
          attributesKeyArray = attributesKeyArray.filter(attributeKey => attributeKey !== key) //remove key from list
        }
      }
      if (objectHasValues(attributes)) {
        return (
          <div className={"attributes-container " + section}>
            <h2 className="attributes-heading">{section}</h2>
  
              <div className="attributes-section">
                { 
                  attributesKeyArray.map(( key ) => (
                      <AttributeItem key={key} section={section} name={key} value={ attributes[key] } />
                  ))
                }  
              </div>
  
          </div>
        )
      }
      else {
        return null
      }
        
    }
    
}
